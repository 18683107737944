import CInputDateRange from '@/components/CInputDateRange'
import CSelectSearch from '@/components/CSelectSearch'
import table from '@/mixin/table'
import { BtoF } from '@/services/MomentService'
import UpdateTasks from '@/components/UpdateTasks'
import AccordionItem from '@/components/Page/AccordionItem'
import GCFilter from '@/components/GCFilter/index'
import modalComponent from '@/mixin/modalComponent'
import Gate from '@/services/Gate/Gate'
import { notifyByPlan } from '@/services/Notify/Toasts'
import NoTasks from '@/components/SmartTable/partials/NoTasks.vue'

export default {
  name: 'UserTasks',
  mixins: [table, modalComponent],
  inject: ['toast', 'mountedComponent'],
  components: {
    NoTasks,
    CInputDateRange,
    CSelectSearch,
    UpdateTasks,
    AccordionItem,
    GCFilter,
  },
  data() {
    return {
      exceptColumn: ['company_name'],
      tasks: [],
      status: '',
      tableFilterProjects: [],
      selectedTasks: [],
      editTasks: false,
      tempFilter: {},
      fields: [
        {
          key: 'task_name_with_count',
          label: 'Task Name',
          _style: '',
        },
        { key: 'status', label: 'Status', _style: '' },
        {
          key: 'task_id',
          label: 'Task ID',
          _style: '',
        },
        {
          key: 'project_name',
          label: 'Project',
          _style: '',
          link: { name: 'Project', params: { id: 'project_id' } },
        },
        {
          key: 'required_action',
          label: 'Required action',
          _style: '',
        },
        { key: 'due_date', label: 'Due Date', _style: '' },
        // { key: 'status', label: 'Status', _style: '' },
        { key: 'type', label: 'Type', _style: '' },
        { key: 'priority', label: 'Priority', _style: '' },
        {
          key: 'internal_assignee',
          label: 'Internal Assignee',
          _style: '',
          link: {
            name: 'CompanyUser',
            params: { user_id: 'internal_assignee_id', id: 'company_id' },
          },
        },
        {
          key: 'external_assignee',
          label: 'External Assignee',
          _style: '',
          link: {
            name: 'CompanyUser',
            params: {
              user_id: 'external_assignee_id',
              id: 'external_company_id',
            },
          },
        },
        {
          key: 'scope_name',
          label: 'Scope Name',
          link: {
            name: 'ProjectScopeEdit',
            params: {
              id: 'project_id',
              scopeId: 'scope_id',
            },
          },
        },
        { key: 'package', label: 'Assigned Contract Folder', _style: '' },
        // { key: 'reminders', label: 'Reminder', _style: '' },
      ],
    }
  },
  watch: {
    tableFilterProjects() {
      this.fetchData()
    },
    status() {
      this.fetchData()
    },
  },
  computed: {
    isOwner() {
      return this.authUser.id === parseInt(this.$route.params.user_id)
    },
  },
  methods: {
    async handleEditTask() {
      const check = await Gate.can('bulkAction', 'task')
      if (!check) {
        notifyByPlan()
        return false
      }
      this.editTasks = check
    },
    clickBulkAction() {
      if (!this.selectedTasks.length)
        this.toast(
          'error',
          `Select task(s) to work with the 'Bulk Action' field`,
        )
    },
    goToView(item) {
      if (item.type === 'Request documents') {
        this.$router.push({
          name: 'TaskRequest',
          params: { id: item.project_id, task_id: item.id },
        })
      } else if (item.type === 'Contracts') {
        this.$router.push({
          name: 'TaskContracts',
          params: { id: item.project_id, task_id: item.id },
        })
      } else if (item.type === 'Acknowledge') {
        this.$router.push({
          name: 'TaskAcknowledge',
          params: { id: item.project_id, task_id: item.id },
        })
      }
    },
    madData(data) {
      return data.map((item) => {
        return {
          id: item.id,
          company_id: item.company_id,
          task_name: item.task_name,
          task_name_with_count: item.task_name_with_count,
          task_id: item.task_id,
          project_name: item.project_name,
          required_action: item.required_action,
          due_date: BtoF({ value: item.due_date }),
          is_overdue: item.is_overdue,
          status: item.status,
          priority: item.priority,
          internal_assignee_id: item.internal_assignee_id,
          internal_assignee: item.internal_assignee,
          external_assignee_id: item.external_assignee_id,
          external_assignee: item.external_assignee,
          external_company_id: item.external_company_id,
          scope_name: item.scope_name,
          scope_id: item.scope_id,
          is_internal: item.is_internal,
          project_id: item.project_id,
          package: item.package,
          reminders: item.reminders,
          type: item.type,
        }
      })
    },
    makeQuery(f = {}) {
      f = Object.assign(this.tempFilter, f)
      return {
        ...f,
        per_page: this.perPage,
        page: this.activePage,
        sort_field: this.sort && this.sort.column,
        sort_direction: this.sort && this.sort.state,
        user_id: this.$route.params.user_id || '',
      }
    },
    fetchData(filter = {}) {
      this.key++
      this.loadingTable = true

      this.$http.users
        .getTasks(this.makeQuery(filter))
        .then((response) => {
          this.key++
          this.tasks = this.madData(response.data.data)
          this.pages = response.data.meta.last_page
          if (this.activePage > this.pages) {
            this.activePage = this.activePage - 1
          }
        })
        .finally(() => (this.loadingTable = false))
    },
    setCheck(value) {
      this.selectedTasks = value.map((i) => i.id)
    },
    closeEdit() {
      this.editTasks = false
      this.fetchData()
    },
    async eventRevokeTasks() {
      const check = await Gate.can('bulkAction', 'task')
      if (!check) {
        notifyByPlan()
        return false
      }
      this.$modal
        .open({
          close: 'Close',
          success: 'Revoke',
          label: 'Do you want to revoke selected tasks?',
        })
        .then(() => {
          this.eventRevokeTasks()
          this.$http.tasks
            .editTasksMass({
              task_id: this.selectedTasks,
              status: 'Revoked',
            })
            .then(() => {
              this.toast('success', 'The tasks have been revoked successfully')
              this.modal = false
              this.selectedTasks = []
              this.fetchData()
            })
            .catch(({ response }) => {
              if (response.data.errors?.status?.length) {
                this.toast('error', response.data.errors.status[0])
                return
              }
              this.errors = response.data.errors
                ? this.getErrors(response.data.errors)
                : {}

              response.status === 403
                ? this.toast(
                    'error',
                    'It is impossible to revoke tasks assigned to your company',
                  )
                : this.toast('error', response.data.message)
            })
            .finally(() => this.$modal.close())
        })
    },
  },
}
